import React, { useState, useEffect } from 'react'; import './App.css';
import logo from './images/uv2.png';
import homeIcon from './images/home_icon.png';
import helpIcon from './images/operator.png';
import profilePlaceholder from './images/samuraipfp.png';
import inventoryBackground from './images/inventory.png';
import assemblyBackground from './images/assembly.png';

function App() {
    const [word, setWord] = useState('Inventory Box');  // initial value

    useEffect(() => {
        // Fetch the word from the PHP script when the component mounts
        fetch('http://universev2.eworldmauritius.com/wordReceiver.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                word: 'exampleWord',  // replace with actual word you want to send
            }),
        })
            .then(response => response.json())
            .then(data => setWord(data.word))  // Update the word in the state
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <div className="App">
            <aside className="profile-column">
                <img src={logo} alt="Universe v2 Logo" className="app-logo" />
                <img src={profilePlaceholder} alt="Profile" className="profile-picture" />
                <h3 className="username">San Mahoney</h3>
                <p className="link-title dashboard">Dashboard</p>
                <div className="link">
                    <img src={homeIcon} alt="Home" className="icon" />
                    <span className="link-text">Home</span>
                </div>
                <p className="link-title help">Help</p>
                <div className="link">
                    <img src={helpIcon} alt="Help" className="icon" />
                    <span className="link-text">Contact us</span>
                </div>
            </aside>
            <main>
                <div className="cards-container">
                    <div className="card inventory-card" style={{ backgroundImage: `url(${inventoryBackground})` }}>
                        <h1 className="card-title">{word}</h1>
                    </div>
                    <div className="card assembly-card" style={{ backgroundImage: `url(${assemblyBackground})` }}>
                        <h1 className="card-title">Assembly</h1>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default App;
